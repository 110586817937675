import React from "react"
import '../assets/css/theme.css'
import '../assets/css/app.css'

const Layout = (props) => (
  <>
    {/* 
      <div className='col-md-2 bg-dark-theme p-0' style={{height: '100vh'}}>
        <SideNav />
      </div>
      */}
    <div className='bg-dark-main d-flex' style={{flexDirection: 'column', minHeight: '100vh', overflow: 'auto',}}>
        {props.children}
    </div>
  </>




)

export default Layout
