import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import firebase from "gatsby-plugin-firebase"

const SEO = props => {
  useEffect(() => {
    if (firebase) firebase.analytics().logEvent("render")
  }, [])
  return (
    <>
    <Helmet>
      <title>{props.title}</title>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600&family=Sarabun:wght@400;600&family=Mitr:wght@400;500&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <meta property="og:url" content={`https://thaco.tech/${props.name}`} />
      <meta property="og:title" content="THACO 2020" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="th_TH" />
      <meta
        property="og:image"
        content={"https://thaco.tech" + require("../assets/images/banner.png")}
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta
        property="og:image:alt"
        content="Thailand Computing Olympiad 2020"
      />
      <meta
        property="og:description"
        content={`การแข่งขันเขียนโปรแกรม ที่จะทำให้ "โค้ดดิ้ง" ไม่เหมือนเดิมอีกต่อไป`}
      />
    </Helmet>
    </>
  )
}

export default SEO
